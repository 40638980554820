import React, { useRef, useState } from 'react';
import { ModuleBase, Media, Stack, Text, HeadingTag, Link, Box } from '@/components';
// import { splitWords } from '@/animations';
import PlayIcon from '@/assets/play.svg';
import PauseIcon from '@/assets/pause.svg';
import Mute from '@/assets/mute.svg';
import Sound from '@/assets/Sound.svg';
import CarouselIndicator from '@/assets/carouselIndicator.svg';
import { useTranslation } from 'next-i18next';
import { MediaLightboxCarousel } from '@/components';
import classNames from 'classnames';

const HeroCenterAlignedModule = ({ data }) => {
  const { t } = useTranslation('common');
  const media = useRef();

  const [isPlaying, setisPlaying] = useState(data.backgroundMedia.autoPlay);
  const [isMuted, setIsMuted] = useState(true);
  const [unShowCoverImage, setShowCoverImage] = useState(data.backgroundMedia.autoPlay);
  const [activeImage, setActiveImage] = useState(null);

  const openFullscreen = () => {
    media?.current?.openFullscreen();
  };

  const handlePlayClick = () => {
    openFullscreen();
    media?.current?.play();
    media?.current?.unMute();
    setIsMuted(false);
    setisPlaying(true);
    setShowCoverImage(true);
  };

  const handlePauseClick = () => {
    if (isPlaying) {
      media?.current?.pause();
      setisPlaying(false);
    } else {
      media?.current?.play();
      setisPlaying(true);
      setShowCoverImage(true);
    }
  };

  const handleUnmuteClick = () => {
    if (isMuted) {
      media?.current?.unMute();
      setIsMuted(false);
    } else {
      media?.current?.mute();
      setIsMuted(true);
    }
  };

  const onFullscreenExit = () => {
    media?.current?.mute();
    setShowCoverImage(false);
    setisPlaying(false);
  };

  return (
    <ModuleBase data={data} className="relative h-dvh overflow-hidden text-silk">
      {data.backgroundMedia.isOnlyImage &&
        data.lightboxImages &&
        data.lightboxImages?.length > 0 &&
        activeImage !== null && (
          <MediaLightboxCarousel
            slides={data.lightboxImages}
            moduleId={data.moduleId}
            activeImage={activeImage}
            onClose={() => setActiveImage(null)}
          />
        )}
      {data.backgroundMedia && (
        <div className="absolute left-0 right-0 top-0 h-full w-full">
          <Media
            ref={media}
            media={data.backgroundMedia}
            widths={{ xs: 430, sm: 768, md: 1024, lg: 1280, xl: 1440, xxl: 2560 }}
            heights={{ xs: 750, sm: null, md: 1080, lg: 1080, xl: null, xxl: null }}
            cover
            dataNotLazy
            controls={false}
            onFullscreenExit={onFullscreenExit}
            className="overlay"
            unShowCoverImage={unShowCoverImage}
          />
        </div>
      )}

      <Stack
        className="container relative flex h-full flex-col items-center justify-center pb-10 text-center md:w-4/5 lg:w-3/5"
        // onClick={openFullscreen}
        role="contentinfo"
        aria-label="contentinfo"
      >
        <div
          className={classNames(
            'title-wrapper mt-auto',
            { '!mt-0': !data.backgroundMedia.videoFromGallery && data.backgroundMedia.vimeoId === '' },
            { 'lg:mt-0': !data.backgroundMedia.autoPlay && !data.backgroundMedia.allowFullScreen },
            { 'lg:mt-0': data.backgroundMedia.autoPlay && !data.backgroundMedia.allowFullScreen },
            { 'lg:mt-0': data.backgroundMedia.autoPlay && data.backgroundMedia.allowFullScreen },
          )}
        >
          {data.headingTitle && <HeadingTag animate data={data.headingTitle} className="title-01 justify-center" />}
        </div>

        {(data.description || data.primaryCTA || data.secondaryCTA) && (
          <div className="color-from-bg title-05">
            <Text
              dangerouslySetInnerHTML={{ __html: data.description }}
              className="mt-6 text-silk md:mt-6 lg:mt-3"
              role="heading"
              aria-level="7"
            />

            {(data.primaryCTA || data.secondaryCTA) && (
              <div className="mt-10 flex justify-center gap-2">
                <Link animate className="btn primary dark" link={data.primaryCTA} />

                <Link animate className="btn secondary dark" link={data.secondaryCTA} />
              </div>
            )}
          </div>
        )}

        {data.backgroundMedia.isOnlyImage && data.lightboxImages && data.lightboxImages?.length > 0 && (
          <button
            onClick={() => setActiveImage(0)}
            className="interactive-01 mt-10 inline-flex h-11 w-fit items-center justify-center gap-2 rounded bg-silk/20 px-6 py-4 backdrop-blur-sm md:mt-[77px] lg:mt-14"
          >
            <CarouselIndicator className="scale-125" />
            <span className="mt-px">{t('general.$viewImages')}</span>
          </button>
        )}

        {data.backgroundMedia?.allowFullScreen &&
          (data.backgroundMedia?.vimeoId || data.backgroundMedia?.videoFromGallery) && (
            <Box className={classNames({ 'mb-auto': !data.backgroundMedia.autoPlay })}>
              <button
                onClick={handlePlayClick}
                className={classNames(
                  'interactive-01 mt-10 flex items-center justify-center rounded-[4px] bg-silk/20 px-6 py-4 backdrop-blur-sm md:mt-16 lg:mt-12',
                )}
              >
                <PlayIcon className="ltr:mr-2 rtl:ml-2" role="presentation" />
                <span>{t('general.$watchVideo')}</span>
              </button>
            </Box>
          )}

        {((data.backgroundMedia?.vimeoId && !data.backgroundMedia.allowFullScreen) ||
          (data.backgroundMedia?.vimeoId && data.backgroundMedia.autoPlay) ||
          (data.backgroundMedia?.videoFromGallery && !data.backgroundMedia.allowFullScreen) ||
          (data.backgroundMedia?.videoFromGallery && data.backgroundMedia.autoPlay)) && (
          <Box className="mt-auto flex gap-2 lg:mt-14">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-silk/20 backdrop-blur-sm">
              <button
                onClick={handlePauseClick}
                className="interactive-01 flex h-[44px] w-[44px] items-center justify-center rounded-full border border-silk"
                aria-label={isPlaying ? t('general.$pauseVideo') : t('general.$playVideo')}
              >
                {isPlaying ? <PauseIcon role="presentation" /> : <PlayIcon role="presentation" />}
              </button>
            </div>

            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-silk/20 backdrop-blur-sm">
              <button
                onClick={handleUnmuteClick}
                className="interactive-01 flex h-[44px] w-[44px] items-center justify-center rounded-full border border-silk"
                aria-label={t('general.$muteUnmute')}
              >
                {isMuted ? <Mute role="presentation" /> : <Sound role="presentation" />}
              </button>
            </div>
          </Box>
        )}
      </Stack>
    </ModuleBase>
  );
};

export default HeroCenterAlignedModule;
