import React, { useState, useEffect } from 'react';
import { Link } from '@/components';
import DropdownFilter from './DropdownFilter';
import {
  pushValuesToRouteQuery,
  removeValuesFromRouteQuery,
  cleanQueryData,
  assignValuesFromRouteQuery,
  getSearchFilters,
} from '@/utils';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Filters from '@/assets/filters.svg';
import Close from '@/assets/close.svg';
import Plus from '@/assets/plus.svg';
import Minus from '@/assets/minus.svg';
import { createPortal } from 'react-dom';

const SearchFilters = ({ filters, cardType, isDark, onChange, queryMode, totalCount, pageSize, ...props }) => {
  const [origFilters, setOrigFilters] = useState();
  const [filterValues, setFilterValues] = useState();
  const [mobileFilterValues, setMobileFilterValues] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState([]);
  const router = useRouter();

  const { t } = useTranslation('common');

  useEffect(() => {
    setIsLoaded(true);
    if (filters) {
      setOrigFilters(filters);
      let newFilterValues = { ...filterValues };
      if (!filterValues) {
        filters.forEach((element) => {
          newFilterValues[element.filterKey] = [];
        });
      }
      assignValuesFromRouteQuery(router, newFilterValues);
      setFilterValues(newFilterValues);
      setMobileFilterValues(newFilterValues);
      const newMobileFiltersOpen = [];
      getSearchFilters(router).forEach((element) => {
        newMobileFiltersOpen.push(element.FieldName);
      });
      setMobileFiltersOpen(newMobileFiltersOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, router.query]);

  const _onChange = (value) => {
    pushFiltersToRoute({ ...filterValues, ...value });
  };

  const pushFiltersToRoute = (newFilterValues) => {
    const newQueryParams = {
      ...newFilterValues,
      page: null,
    };

    if (queryMode) {
      pushValuesToRouteQuery(router, newQueryParams);
    } else if (onChange) {
      cleanQueryData(newQueryParams);
      onChange(newQueryParams);
    }
  };

  const showFilterDialog = (e) => {
    e.preventDefault();
    if (!filterOpen) {
      setFilterOpen(true);
      document.body.style.overflow = 'hidden';
    }
  };

  const closeFilterDialog = () => {
    if (filterOpen) {
      setFilterOpen(false);
      document.body.style.overflow = 'auto';
    }
  };

  const clearAll = (e) => {
    e.preventDefault();
    removeValuesFromRouteQuery(router, mobileFilterValues);
    setMobileFilterValues({});
    setFilterOpen(false);
    document.body.style.overflow = 'auto';
  };
  // const applyFilters = (e) => {
  //   e.preventDefault();
  //   if (filterOpen) {
  //     pushFiltersToRoute({ ...mobileFilterValues });
  //     setFilterOpen(false);
  //     document.body.style.overflow = 'auto';
  //   }
  // };

  const changeMobileFiltersOpen = (filterKey) => {
    if (mobileFiltersOpen.includes(filterKey)) {
      return () => setMobileFiltersOpen(mobileFiltersOpen.filter((item) => item !== filterKey));
    } else {
      return () => setMobileFiltersOpen([...mobileFiltersOpen, filterKey]);
    }
  };

  const toggleMobileFilterValues = (filterKey, filterGuid, callApply = false) => {
    return (e) => {
      e.preventDefault();
      const newMobileFilterValues = { ...mobileFilterValues };
      if (newMobileFilterValues[filterKey]) {
        if (newMobileFilterValues[filterKey].includes(filterGuid)) {
          newMobileFilterValues[filterKey] = newMobileFilterValues[filterKey].filter((item) => item !== filterGuid);
        } else {
          newMobileFilterValues[filterKey].push(filterGuid);
        }
      } else {
        newMobileFilterValues[filterKey] = [filterGuid];
      }
      setMobileFilterValues(newMobileFilterValues);
      if (callApply) {
        pushFiltersToRoute({ ...newMobileFilterValues });
      }
    };
  };

  const isMobileFilterActive = (filterKey, filterGuid) => {
    return mobileFilterValues && mobileFilterValues[filterKey] && mobileFilterValues[filterKey].includes(filterGuid);
  };

  const mobileFilterElement = (index, filter) => {
    return (
      <div key={index} className="border-b border-b-charcoal/25 py-2">
        <div
          className={classNames(
            'flex h-10 cursor-pointer items-center justify-between',
            mobileFiltersOpen.includes(filter.filterKey) ? 'active font-semibold' : '',
          )}
          onClick={changeMobileFiltersOpen(filter.filterKey)}
        >
          {filter.filterLabel}
          {mobileFiltersOpen.includes(filter.filterKey) ? (
            <div className="flex h-10 w-10 items-center justify-center">
              <Minus className="h-6 w-6" />
            </div>
          ) : (
            <div className="flex h-10 w-10 items-center justify-center">
              <Plus className="h-6 w-6" />
            </div>
          )}
        </div>
        <div
          className={classNames(
            'grid transition-all duration-700',
            mobileFiltersOpen.includes(filter.filterKey) ? 'my-2' : '',
          )}
          style={{
            gridTemplateRows: mobileFiltersOpen.includes(filter.filterKey) ? 'min-content 1fr' : 'min-content 0fr',
          }}
        >
          <div></div>
          <div className="flex flex-col gap-4 overflow-hidden">
            {filter.filters.map((option, findex) => (
              <label
                key={`${index}-${findex}`}
                class="z-50 flex h-full w-full cursor-pointer gap-2"
                onClick={toggleMobileFilterValues(filter.filterKey, option.filterGuid, true)}
              >
                <input
                  key={`${index}-${findex}`}
                  type="checkbox"
                  readOnly
                  value=""
                  checked={isMobileFilterActive(filter.filterKey, option.filterGuid)}
                />
                <div class="mr-2 block cursor-pointer">{option.name}</div>
              </label>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const mobileFilterDialog = () => {
    return (
      <div
        className={classNames(
          'fixed left-0 top-0 z-50 h-full w-full translate-x-full bg-silk p-4 duration-700',
          filterOpen ? '!translate-x-0' : '',
        )}
      >
        <div className="flex h-full flex-col">
          <div className="mb-8 flex h-10 w-full items-center justify-between">
            {t('general.$filters')}
            <div className="cursor-pointer" onClick={closeFilterDialog}>
              <Close />
            </div>
          </div>
          <div className="grow overflow-y-auto border-t border-t-charcoal/25">
            {filters.map((filter, index) => mobileFilterElement(index, filter))}
          </div>
          <div className="flex flex-col gap-4 bg-charcoal/5 px-4 py-6">
            <Link
              animate
              className="btn secondary w-full"
              onClick={clearAll}
              link={{ text: t('general.$clearAll'), href: window.location.href }}
            />
            <div
              animate
              className="btn primary w-full"
              onClick={closeFilterDialog}
              link={{ text: t('general.$apply') }}
            >
              {t('general.$apply')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getFilterSize = () => {
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    const existingFilters = [];

    for (const [key, value] of params.entries()) {
      origFilters.forEach((origFilter) => {
        origFilter.filters.forEach((filter) => {
          if (origFilter.filterKey === key && filter.filterGuid === value) {
            existingFilters.push({
              filterKey: origFilter.filterKey,
              filterGuid: value,
            });
          }
        });
      });
    }

    return existingFilters.length;
  };

  const getFilterSizeText = () => {
    const size = getFilterSize();
    return size > 0 ? `(${size})` : '';
  };

  const hasClearButton = () => {
    return !!getFilterSize() > 0;
  };

  if (!filters) return;
  return (
    <>
      {isLoaded && cardType === 'Article' && (
        <div className="mb-6 flex flex-wrap items-end gap-2 lg:mb-0 lg:items-center lg:justify-center lg:gap-4">
          <div
            className={classNames(
              'cursor-pointer rounded-full px-6 py-3 font-montserrat text-xs font-medium uppercase leading-[14px]',
              mobileFilterValues?.['tag']?.length === 0
                ? isDark
                  ? 'bg-silk/25'
                  : 'bg-charcoal/25'
                : isDark
                  ? 'bg-silk/5'
                  : 'bg-charcoal/5',
            )}
            onClick={clearAll}
          >
            {t('general.$all')}
          </div>
          {filterValues &&
            filters
              .filter((filter) => filter.filterKey === 'tag' || filter.filterKey.includes('-tag'))
              .map((filter, index) =>
                filter.filters.map((option, findex) => (
                  <div
                    key={`${index}-${findex}`}
                    className={classNames(
                      'cursor-pointer rounded-full px-6 py-3 font-montserrat text-xs font-medium uppercase leading-[14px]',
                      isMobileFilterActive(filter.filterKey, option.filterGuid)
                        ? isDark
                          ? 'bg-silk/25'
                          : 'bg-charcoal/25'
                        : isDark
                          ? 'bg-silk/5'
                          : 'bg-charcoal/5',
                    )}
                    onClick={toggleMobileFilterValues(filter.filterKey, option.filterGuid, true)}
                  >
                    {option.name}
                  </div>
                )),
              )}
        </div>
      )}
      {filters.length > 0 && (
        <div
          className="my-6 hidden flex-col justify-between gap-3 md:flex-row md:items-center md:gap-6 lg:my-[34px] lg:flex"
          {...props}
        >
          <div className="flex-col justify-between gap-3 md:flex-row md:items-center md:gap-6 lg:flex lg:justify-start">
            {filterValues &&
              filters
                .filter((filter) =>
                  cardType === 'Article'
                    ? filter.filterKey !== 'tag' && filter.filterKey.includes('-tag') === false
                    : filter,
                )
                .map((filter, index) => (
                  <div key={index} className="w-full lg:w-[212px]">
                    <DropdownFilter
                      className={classNames(
                        'input dropdown w-full rounded border [&_.popover-title]:font-montserrat [&_.popover-title]:font-medium [&_label.checkbox>div]:font-montserrat',
                        isDark ? 'border-silk/50 !bg-silk text-charcoal' : 'border-charcoal/50 bg-silk text-charcoal',
                      )}
                      value={filterValues[filter.filterKey] || []}
                      filter={filter}
                      onChange={_onChange}
                    />
                  </div>
                ))}
          </div>
          <div>
            {isLoaded && hasClearButton() && (
              <Link
                animate
                className={classNames('btn tertiary w-full !opacity-100', isDark ? 'dark' : 'light')}
                onClick={clearAll}
                link={{ text: `clear all`, href: window.location.href }}
              >
                <Close className={classNames(isDark ? '[&_path]:stroke-silk' : '[&_path]:stroke-charcoal')} />
                {t('general.$clearFilters')} {getFilterSizeText()}
              </Link>
            )}
          </div>
        </div>
      )}
      <div className="flex lg:hidden [&>div]:w-full">
        {isLoaded && (
          <>
            <Link
              animate
              className={classNames(
                'btn secondary w-full px-[17px] text-[14px] capitalize tracking-[.14px] md:w-auto',
                isDark ? 'dark' : 'light',
              )}
              onClick={showFilterDialog}
              link={{ text: t('general.$filters'), href: window.location.href }}
            >
              <div className="flex w-10 justify-center">
                <Filters className={classNames(isDark ? '[&_path]:fill-silk' : '[&_path]:fill-charcoal')} />
              </div>
              {t('general.$filters')}
            </Link>
            {createPortal(mobileFilterDialog(), document.body)}
          </>
        )}
      </div>
      {isLoaded && filters.length > 0 && (
        <div className="body-03 my-6 font-[600] lg:my-[34px]">
          {totalCount > 0 &&
            t('general.$showingResults', {
              showing: pageSize ? Math.min(pageSize, totalCount) : 0,
              results: totalCount ?? 0,
            })}
          {totalCount === 0 && t('general.$noResults')}
        </div>
      )}
    </>
  );
};
export default SearchFilters;
