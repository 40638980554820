import { EntityCard, ModuleBase, Pagination, SearchFilters, Text, Stack, HeadingTag, Link } from '@/components';
import { selectPageId, selectLanguages } from '@/store/index';
import { getSearchFilters, scrollIntoView, isColorDark } from '@/utils';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ArticleFeatureCard from '../components/cards/ArticleFeatureCard';
// import ComponentAnimation from '@/animations/animationComponent/ComponentAnimation';
// import AnimationStarter from '@/animations/animationComponent/AnimationStarter';

const CardListingGridModule = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState();
  const [totalCount, setTotalCount] = useState();
  const [pageSize, setPageSize] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const router = useRouter();
  const fetchController = useRef(null);
  const queryData = useRef({ filters: [], page: 1 });
  const pageId = useSelector(selectPageId);
  const languages = useSelector(selectLanguages);
  const container = useRef();
  const cardsContainer = useRef();
  const [cardsContainerHeight, setCardsContainerHeight] = useState(0);
  const isDark = isColorDark(data.backgroundColour);

  const hasFilter = useRef();
  const origFilters = useRef();
  const hasPagination = useRef();

  const transition = {
    type: 'spring',
    damping: 30,
    stiffness: 150,
  };

  useEffect(() => {
    hasFilter.current = data.filtersAndCards.filter?.length > 0;
    origFilters.current = data.filtersAndCards.filter;
    hasPagination.current = data.filtersAndCards.totalPages > 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newFilters = { filters: getSearchFilters(router), page: Number(router.query.page || 1) };
    if (data?.filtersAndCards?.cards && JSON.stringify(newFilters) === JSON.stringify(queryData.current)) {
      setCards(data.filtersAndCards.cards);
      setTotalCount(data.filtersAndCards.totalCount);
    } else if (JSON.stringify(newFilters) !== JSON.stringify(queryData.current) && pageId) {
      if (hasFilter.current || hasPagination.current) {
        queryData.current = newFilters;
        getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, data]);

  useEffect(() => {
    let newFilters = { filters: getSearchFilters(router), page: Number(router.query.page || 1) };
    if (JSON.stringify(newFilters) !== JSON.stringify(queryData.current) && pageId) {
      if (hasFilter.current || hasPagination.current) {
        queryData.current = newFilters;
        getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, router.query]);

  useEffect(() => {
    setTimeout(() => {
      if (cards?.length > 0) {
        setLoading(false);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  const getLanguageCodeByUrl = () => {
    const ulr = window.location.href;
    let langCode = '';
    languages.forEach((lang) => {
      if (ulr.includes(lang.languageUrl)) {
        langCode = lang.language === 'en-us' ? '' : lang.language;
      }
    });
    return langCode;
  };

  const getExistingFilterSize = () => {
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    const existingFilters = [];

    for (const [key, value] of params.entries()) {
      origFilters.current.forEach((origFilter) => {
        origFilter.filters.forEach((filter) => {
          if (origFilter.filterKey === key && filter.filterGuid === value) {
            existingFilters.push({
              filterKey: origFilter.filterKey,
              filterGuid: value,
            });
          }
        });
      });
    }
    return existingFilters.length;
  };

  const getData = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));

    setCards([]);
    setCardsContainerHeight(cardsContainer.current.offsetHeight);
    const startTime = Date.now();
    if (fetchController.current) {
      fetchController.current.abort();
    }
    const controller = new AbortController();
    fetchController.current = controller;
    const pageSizeFromQuery =
      parseInt(
        queryData.current.filters.filter(
          (item) => item.FieldName === 'pageSize' || item.FieldName.includes('pageSize'),
        )?.[0]?.FieldGuids[0],
      ) || null;
    const reqData = {
      PageId: pageId,
      CardsType: data.filtersAndCards.cardType,
      destinations: data.filtersAndCards.destinations,
      Featured: 0,
      culture: getLanguageCodeByUrl(),
      Key: data.filtersAndCards.key,
    };

    if (hasFilter.current && getExistingFilterSize() > 0) {
      reqData.Filters = queryData.current.filters
        .filter(
          (item) =>
            item.FieldName !== 'pageSize' &&
            item.FieldName.includes('pageSize') === false &&
            item.FieldName.includes(data.filtersAndCards.key),
        )
        .map((item) => ({ FieldName: item.FieldName, FieldGuids: item.FieldGuids }));
    }

    if (hasPagination.current) {
      reqData.PageSize = pageSizeFromQuery ?? data.filtersAndCards.pageSize;
      reqData.PageNumber = queryData.current.page;
    } else {
      reqData.PageSize = data.filtersAndCards.pageSize;
      reqData.PageNumber = 1;
    }

    const isPreview = !!router.query.isPreview;
    const dataRes = await fetch(`${!isPreview ? '/api' : ''}/umbraco/api/ProductCard/GetCards`, {
      method: 'POST',
      signal: fetchController.current?.signal,
      body: JSON.stringify(reqData),
    }).catch(console.error);

    if (dataRes && dataRes.ok) {
      const data = await dataRes.json();

      const setData = () => {
        setCards();
        setTimeout(() => {
          setCards(data.cards);
          setTotalCount(data.totalCount);
          setPageSize(data.pageSize);
          setCurrentPage(data.currentPage);
          setCardsContainerHeight(0);
        }, 0);
      };
      const requestTime = Date.now() - startTime;
      if (requestTime >= 1300) {
        setData();
      } else {
        setTimeout(setData, 1300 - requestTime);
      }
    } else {
      setLoading(false);
      setCardsContainerHeight(0);
    }
  };

  let gridCols;
  let propertyCardPadding;
  let propertycardBorder;
  if (data.filtersAndCards.cards.length > 0) {
    if (
      data.filtersAndCards.cards[0].moduleName === 'ExperienceSmallCard' ||
      data.filtersAndCards.cards[0].moduleName === 'GenericLargeCard' ||
      data.filtersAndCards.cards[0].moduleName === 'GenericSmallCard' ||
      data.filtersAndCards.cards[0].moduleName === 'ArticleCard'
    ) {
      gridCols = 'grid-cols-[1fr] md:grid-cols-[repeat(2,1fr)]  xl:grid-cols-[repeat(3,1fr)]';
    } else if (data.filtersAndCards.cards[0].moduleName === 'AccommodationCard') {
      gridCols = 'grid-cols-[1fr] md:grid-cols-[repeat(2,1fr)]';
    } else if (data.filtersAndCards.cards[0].moduleName === 'PropertyCard') {
      gridCols = 'grid-cols-[1fr] !gap-0';
    }

    propertyCardPadding =
      data.filtersAndCards.cards[0].moduleName === 'PropertyCard' ? 'px-0' : 'px-4 sm:px-6 lg:px-10';

    propertycardBorder =
      data.filtersAndCards.cards[0].moduleName === 'PropertyCard'
        ? isDark
          ? 'border-t border-t-silk/25 last:border-b border-b-silk/25 py-6 lg:py-8  hover:bg-silk/25'
          : 'border-t border-t-charcoal/25 last:border-b border-b-charcoal/25 py-6 lg:py-8  hover:bg-charcoal/25'
        : 'border-y-0';
  }
  return (
    <ModuleBase data={data} className={classNames(isDark ? 'text-silk' : 'text-charcoal')}>
      <div className={classNames('container', propertyCardPadding)} ref={container}>
        {(data.primaryCTA ||
          data.secondaryCTA ||
          data.preHeading ||
          (data.headingTitle && data.headingTitle.heading) ||
          data.description) && (
          <Stack
            className={classNames(
              'description-wrapper mb-6 md:mb-8 lg:mb-10 lg:flex lg:items-end lg:justify-between',
              data.filtersAndCards.cards.length > 0 && data.filtersAndCards.cards[0].moduleName === 'PropertyCard'
                ? 'px-4 sm:px-6 lg:px-10'
                : 'px-0',
            )}
          >
            <div className="title-description">
              <div className="flex flex-col">
                {data.preHeading && (
                  <Text animate className="color-from-bg label-01 mb-2 font-montserrat !opacity-50">
                    {data.preHeading}
                  </Text>
                )}

                {data.headingTitle && (
                  <HeadingTag
                    animate
                    data={data.headingTitle}
                    className="title-04 mb-3 md:mb-3 lg:mb-[22px]"
                  ></HeadingTag>
                )}

                {data.description && (
                  <Text
                    animate
                    dangerouslySetInnerHTML={{ __html: data.description }}
                    className="body-03 mb-5 max-w-[456px] !opacity-75 lg:mb-0"
                  ></Text>
                )}
              </div>
            </div>

            {(data.primaryCTA || data.secondaryCTA) && (
              <div className="flex flex-col items-start gap-2 md:flex-row lg:items-center">
                <Link animate className={classNames('btn primary', isDark ? 'dark' : 'light')} link={data.primaryCTA} />
                <Link
                  animate
                  className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                  link={data.secondaryCTA}
                />
              </div>
            )}
          </Stack>
        )}

        {data.filtersAndCards.displayFilters && (
          <SearchFilters
            isDark={isDark}
            filters={data.filtersAndCards.filter}
            cardType={data.filtersAndCards.cardType}
            totalCount={totalCount}
            pageSize={pageSize || data.filtersAndCards.pageSize}
            queryMode
          />
        )}

        <motion.div
          variants={{
            inactive: {
              opacity: 0,
            },
            active: {
              opacity: 1,
            },
          }}
          transition={transition}
          initial="inactive"
          animate={loading ? 'inactive' : 'active'}
        >
          <motion.div
            variants={{
              inactive: {
                opacity: 0,
                y: 20,
              },
              active: {
                opacity: 1,
                y: 0,
              },
            }}
            transition={transition}
          >
            {data.filtersAndCards.featuredCard && currentPage === 1 && (
              <ArticleFeatureCard data={data.filtersAndCards.featuredCard.props} className={'hidden lg:mb-2 lg:flex'} />
            )}
          </motion.div>
        </motion.div>

        <div ref={cardsContainer} style={{ minHeight: cardsContainerHeight }} className="cards-container">
          {cards && (
            <AnimatePresence>
              <motion.div
                variants={{
                  inactive: {
                    opacity: 0,
                  },
                  active: {
                    opacity: 1,
                  },
                }}
                transition={transition}
                initial="inactive"
                animate={loading ? 'inactive' : 'active'}
              >
                {data.filtersAndCards.featuredCard && currentPage === 1 && (
                  <motion.div
                    variants={{
                      inactive: {
                        opacity: 0,
                        y: 20,
                      },
                      active: {
                        opacity: 1,
                        y: 0,
                      },
                    }}
                    transition={transition}
                    className="mb-4 md:mb-5 lg:hidden"
                  >
                    <ArticleFeatureCard data={data.filtersAndCards.featuredCard.props} className="lg:hidden" />
                  </motion.div>
                )}

                <div className={classNames('grid gap-x-2 gap-y-4 md:gap-y-5 lg:gap-y-6', gridCols)}>
                  {cards.map((card, index) => (
                    <motion.div
                      variants={{
                        inactive: {
                          opacity: 0,
                          y: 20,
                        },
                        active: {
                          opacity: 1,
                          y: 0,
                        },
                      }}
                      transition={{
                        ...transition,
                        delay: 0.3 * (data.filtersAndCards.featuredCard && currentPage === 1 ? index + 1 : index),
                      }}
                      key={card.moduleId}
                      className={classNames(propertycardBorder)}
                    >
                      <EntityCard data={card} key={card.moduleId} backgroundColour={data.backgroundColour} />
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </AnimatePresence>
          )}
          {/* </AnimationStarter> */}
        </div>
        <Pagination
          totalCount={totalCount}
          usePagination={data.filtersAndCards.usePagination}
          defaultPageSize={data.filtersAndCards.defaultPageSize}
          origTotalPages={data.filtersAndCards.totalPages}
          isDark={isDark}
          pageSize={pageSize || data.filtersAndCards.pageSize}
          queryMode
          onChange={() => scrollIntoView(container.current)}
        />
      </div>
    </ModuleBase>
  );
};
export default CardListingGridModule;
