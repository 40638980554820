import React, { useState, useRef } from 'react';
import FormControl from './FormControl';
import classnames from 'classnames';
import { Formik, Field } from 'formik';
import { useGoogleReCaptcha } from '../recaptcha/UseGoogleRecaptcha';
import { isInViewport, scrollIntoView } from '@/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { format, endOfDay } from 'date-fns';

const UmbracoForm = ({
  formDefinition,
  successClass,
  thankYouHeading,
  thankYouMessage,
  submitBtnClass,
  formControlClassName,
  backgroundColour,
  beforeSubmit,
  preValidate,
  popoverProps,
  onSubmitSuccessful,
  ...props
}) => {
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const form = useRef();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const router = useRouter();
  const { t } = useTranslation('common');

  if (!formDefinition || !formDefinition.pages) {
    return null;
  }

  const initialValues = {};
  formDefinition?.pages.forEach((page) =>
    page.fieldsets.forEach((fieldset) =>
      fieldset.columns.forEach((column) =>
        column.fields.forEach((uField) => {
          if (uField.type.name === 'Multiple choice') {
            initialValues[uField.alias] = [uField.settings.defaultValue];
          } else {
            initialValues[uField.alias] = uField.settings.defaultValue;
          }
        }),
      ),
    ),
  );

  const getActivePageFields = () => {
    const fields = [];
    if (formDefinition?.pages[activePageIndex]) {
      formDefinition?.pages[activePageIndex].fieldsets.forEach((fieldset) =>
        fieldset.columns.forEach((column) => column.fields.forEach((uField) => fields.push(uField))),
      );
    }
    return fields;
  };

  const formDefinitionName = formDefinition.name.replace(/[\W_]+/g, '').toLowerCase();

  const nameFix = (str) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : null;
  };

  const validate = (values) => {
    const errors = {};

    if (formDefinition?.pages[activePageIndex]) {
      const fields = getActivePageFields();

      fields.forEach((field) => {
        if (field.required) {
          if (['checkbox', 'Data Consent', 'Date'].includes(field.type.name)) {
            if (!values[field.alias]) {
              errors[field.alias] = t('forms.$mandatoryMessage', {
                fieldName: t(`forms.${formDefinitionName}.$${formDefinitionName}${nameFix(field?.alias)}`),
              });
            }
          } else if (!values[field.alias] || !values[field.alias]?.trim()) {
            errors[field.alias] = t('forms.$mandatoryMessage', {
              fieldName: t(`forms.${formDefinitionName}.$${formDefinitionName}${nameFix(field?.alias)}`),
            });
          }
        }
        if (field.pattern) {
          const re = new RegExp(field.pattern);
          if (!re.test(values[field.alias]?.trim())) {
            errors[field.alias] = t(
              `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(field?.alias)}PatternError`,
            );
          }
        }
      });
      preValidate && preValidate(fields, values, errors);
    }

    setTimeout(() => {
      const element = form.current.querySelector('.input-group.error');

      if (element && !isInViewport(element)) {
        scrollIntoView(element, 10);
      }
    }, 0);

    return errors;
  };

  const next = (errors, setTouched) => {
    if (Object.keys(errors).length === 0) {
      setActivePageIndex(activePageIndex + 1);
    } else {
      const fields = getActivePageFields();
      const touched = {};
      fields.forEach((field) => {
        touched[field.alias] = true;
      });
      setTouched(touched);
    }
  };

  const prev = () => {
    setActivePageIndex(activePageIndex - 1);
  };

  const fixValues = (values) => {
    for (const [key, value] of Object.entries(values)) {
      if (typeof value == 'boolean') {
        values[key] = `${value}`;
      } else if (value && typeof value.getMonth === 'function') {
        values[key] = format(endOfDay(value), "yyyy-MM-dd'T'HH:mm:ss'Z'");
      }
    }
  };

  const submit = async (values) => {
    fixValues(values);
    if (beforeSubmit) {
      beforeSubmit(values);
    }

    const isPreview = !!router.query.isPreview;
    await executeRecaptcha('enquiryFormSubmit').then(async (gReCaptchaToken) => {
      const submitRes = await fetch(`${!isPreview ? '/api' : ''}/umbraco/forms/api/v1/entries/${formDefinition.id}`, {
        method: 'POST',
        body: JSON.stringify({ recaptchaToken: gReCaptchaToken, values }),
      }).catch(console.error);

      if (submitRes.ok) {
        setSubmitted(true);
        onSubmitSuccessful && onSubmitSuccessful(values);
      } else {
        console.log(`Post Error. HTTP Response Code: ${submitRes?.status}`);
      }
    });
  };

  return (
    <div {...props}>
      {submitted ? (
        <AnimatePresence>
          <motion.div
            key="thankYouMessage"
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'tween', duration: 0.8 }}
            className={successClass}
            style={{ backgroundColor: backgroundColour }}
          >
            <p
              className="title-04 color-from-bg mb-3 md:mb-5 md:w-[472px]"
              dangerouslySetInnerHTML={{ __html: thankYouHeading }}
            />
            <div
              className="body-03 color-from-bg opacity-75 md:w-[472px]"
              dangerouslySetInnerHTML={{ __html: thankYouMessage }}
            />
          </motion.div>
        </AnimatePresence>
      ) : (
        <Formik initialValues={initialValues} validate={validate} onSubmit={submit}>
          {({ handleSubmit, isSubmitting, validateForm, setTouched }) => (
            <form ref={form} onSubmit={handleSubmit} className="form">
              <div className="form-inner flex">
                {formDefinition.pages &&
                  formDefinition.pages.map((page, pageIndex) => (
                    <div
                      key={pageIndex}
                      className={classnames('page w-full', activePageIndex === pageIndex ? 'block' : 'hidden')}
                    >
                      {page.fieldsets &&
                        page.fieldsets.map((fieldset) => (
                          <fieldset key={fieldset.id} className="fieldset">
                            {fieldset.caption && <legend className="py-5 pt-0">{fieldset.caption}</legend>}
                            <div className="fieldset-inner flex flex-col lg:flex-row">
                              {fieldset.columns &&
                                fieldset.columns.map((column, columnIndex) => (
                                  <div key={columnIndex} className="column flex w-full flex-col gap-2">
                                    {column.fields &&
                                      column.fields.map((uField) => {
                                        return uField?.type?.name !== 'Hidden' ? (
                                          <Field key={uField.id} name={uField?.alias}>
                                            {({
                                              field, // { name, value, onChange, onBlur }
                                              // form: { touched, errors, dirty }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                              meta,
                                            }) => {
                                              return (
                                                <FormControl
                                                  id={uField.id}
                                                  type={uField?.type?.name}
                                                  label={
                                                    uField?.settings?.showLabel === 'True'
                                                      ? uField?.caption
                                                        ? t(
                                                            `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                              uField?.alias,
                                                            )}Label`,
                                                          ) +
                                                          t(
                                                            `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                              uField?.alias,
                                                            )}Label2`,
                                                            {
                                                              defaultValue: '',
                                                            },
                                                          )
                                                        : null
                                                      : null
                                                  }
                                                  error={meta.touched && meta.error}
                                                  maxLength={uField?.settings?.maximumLength}
                                                  placeholder={
                                                    uField?.settings?.placeholder
                                                      ? t(
                                                          `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                            uField?.alias,
                                                          )}Placeholder`,
                                                        )
                                                      : null
                                                  }
                                                  selectPrompt={
                                                    uField?.settings?.selectPrompt
                                                      ? t(
                                                          `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                            uField?.alias,
                                                          )}Placeholder`,
                                                        )
                                                      : null
                                                  }
                                                  settings={uField?.settings}
                                                  options={uField?.preValues.map(({ caption, value }) => ({
                                                    label: caption,
                                                    value,
                                                  }))}
                                                  required={uField?.required}
                                                  disabled={isSubmitting}
                                                  className={formControlClassName}
                                                  formDefinitionName={formDefinitionName}
                                                  fixedName={nameFix(uField?.alias)}
                                                  {...field}
                                                  popoverProps={popoverProps}
                                                />
                                              );
                                            }}
                                          </Field>
                                        ) : null;
                                      })}
                                  </div>
                                ))}
                            </div>
                          </fieldset>
                        ))}
                    </div>
                  ))}
              </div>
              <div className="button-wrapper mt-4 flex justify-end gap-4">
                {activePageIndex !== 0 && (
                  <button className="btn secondary" onClick={prev}>
                    {formDefinition.previousLabel}
                  </button>
                )}
                {activePageIndex === formDefinition.pages.length - 1 ? (
                  <input
                    type="submit"
                    className={classnames(submitBtnClass || 'btn secondary')}
                    value={t(`forms.${formDefinitionName}.$${formDefinitionName}SubmitButton`)}
                  />
                ) : (
                  <div
                    type="button"
                    className={classnames(submitBtnClass || 'btn primary')}
                    onClick={() => validateForm().then((errors) => next(errors, setTouched))}
                  >
                    {formDefinition.nextLabel}
                  </div>
                )}
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default UmbracoForm;
