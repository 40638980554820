import PropTypes from 'prop-types';
// import GenericCardSmall from './GenericCardSmall';
import GenericCardLarge from './GenericCardLarge';
import GenericSmallCard from './GenericCardSmall';
import ExperienceCardLarge from './ExperienceCardLarge';
import ExperienceSmallCard from './ExperienceSmallCard';
import PropertyCard from './PropertyCard';
import ArticleCardSmall from './ArticleCard';
import AccommodationCard from './AccommodationCard';

import classNames from 'classnames';

const EntityCard = ({ data, backgroundColour, className, ...props }) => {
  let _props = { ...props };

  let Component = null;
  if (data.moduleName === 'GenericLargeCard') {
    Component = GenericCardLarge;
  } else if (data.moduleName === 'GenericSmallCard') {
    Component = GenericSmallCard;
  } else if (data.moduleName === 'AccommodationCard') {
    Component = AccommodationCard;
  } else if (data.moduleName === 'ArticleCard') {
    Component = ArticleCardSmall;
  } else if (data.moduleName === 'ExperienceLargeCard') {
    Component = ExperienceCardLarge;
  } else if (data.moduleName === 'ExperienceSmallCard') {
    Component = ExperienceSmallCard;
  } else if (data.moduleName === 'PropertyCard') {
    Component = PropertyCard;
  }

  return Component ? (
    <Component
      data={{ ...data.props, moduleId: data.moduleId }}
      className={classNames(className, `${data.moduleName}`)}
      {..._props}
      backgroundColour={backgroundColour}
    />
  ) : (
    data.moduleName + ' card not implemented'
  );
};

EntityCard.propTypes = {
  data: PropTypes.object.isRequired,
  large: PropTypes.bool,
};

export default EntityCard;
