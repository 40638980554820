import PropTypes from 'prop-types';
import { ResponsiveImage, Link } from '..';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import tailwindConfig from '@/tailwind.config.js';

// eslint-disable-next-line no-unused-vars
const ArticleCard = ({ data, className, isDragging, backgroundColour, ...props }) => {
  const { t } = useTranslation('common');
  const [desktop, setDesktop] = useState(false);

  useEffect(() => {
    const resize = () => {
      setDesktop(window.innerWidth >= parseInt(tailwindConfig.theme.screens.xl, 10));
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return data.link && desktop ? (
    <Link
      className={classNames(className, 'main-wrapper group flex h-[387px] flex-col bg-silk md:h-[397px] lg:h-[518px]')}
      {...props}
      link={data.link}
      onPointerDownCapture={(e) => e.preventDefault()}
      onClick={(e) => isDragging && e.preventDefault()}
    >
      <div className="max-h-[192px] w-full overflow-hidden md:max-h-[196px] lg:max-h-[266px]">
        {data.image && (
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 645, md: 534, lg: 708 }}
            heights={{ xs: 288, md: 288, lg: 400 }}
            imgProps={{ className: 'transition-transform duration-1000 group-hover:scale-110' }}
          />
        )}
      </div>

      <div className="content-wrapper flex grow flex-col justify-between p-4 font-montserrat xl:px-4 xl:py-6">
        <div className="text-wrapper flex grow flex-col justify-between">
          <div>
            {data.label && <p className="label-01 text-charcoal/50">{data.label}</p>}
            {data.title && (
              <div
                className="title-05 my-1 text-charcoal"
                dangerouslySetInnerHTML={{ __html: data.title }}
                role="heading"
                aria-level="7"
              ></div>
            )}
          </div>
          {data.description && (
            <div
              className="body-04 lg:body-03 mt-auto line-clamp-3 text-charcoal/75 lg:w-[347px]"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          )}
        </div>
      </div>
    </Link>
  ) : (
    <div
      {...props}
      className={classNames(className, 'main-wrapper flex h-[387px] flex-col bg-silk md:h-[397px] lg:h-[518px]')}
    >
      <div className="aspect-[16/9] max-h-[192px] w-full overflow-hidden md:max-h-[196px] lg:max-h-[266px]">
        {data.image && (
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 645, md: 534, lg: 708 }}
            heights={{ xs: 288, md: 288, lg: 400 }}
          />
        )}
      </div>

      <div className="content-wrapper flex h-[190px] grow flex-col justify-between p-4 xl:px-4 xl:py-6">
        <div className={classNames('text-wrapper flex grow flex-col xl:justify-between')}>
          <div>
            {data.label && <p className="label-01 text-charcoal/50">{data.label}</p>}
            {data.title && (
              <div
                className="title-05 mb-3 mt-1 text-charcoal md:mb-5 xl:my-1"
                dangerouslySetInnerHTML={{ __html: data.title }}
                role="heading"
                aria-level="7"
              ></div>
            )}
          </div>
          {data.description && (
            <div
              className="body-04 lg:body-03 line-clamp-3 text-charcoal/75"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          )}

          {data.link && (
            <div className="mt-auto xl:hidden">
              <Link className="btn tertiary" link={data.link}>
                {t('general.$viewMore')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ArticleCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ArticleCard;
